<template>
  <div id="wrap" class="glowup_wrap">
      <div id="container">
        <div class="content">
          <div class="bg"></div>
          <div class="main_box">
            <div class="main">
              <h1 class="title" v-html="$t('text_app_title')"></h1>
              <p v-if="language !== 'ko'" class="text" v-html="$t('text_app_desc')"></p>
              <div class="download">
                <span class="blind" v-html="$t('text_app_download')"></span>
                <a href="https://apps.apple.com/app/id1619090725" class="btn_app_store">
                  <span class="blind" v-html="$t('text_app_store')"></span>
                </a>
              </div>
            </div>
          </div>
          <!-- PC 해상도 768px 이하에서 노출되는 언어선택영역 -->
          <div class="language_select">
            <div class="select_box">
              <!-- [D] 선택된 언어를 .text에 적용. 한국어 선택시 class에 ko 추가 (폰트가 다름) -->
              <span :class="'text ' + language" v-html="$t('text_selected_language')"></span>
              <select @change="changeLanguage">
                <option value="ko" :selected="language === 'ko'">한국어</option>
                <option value="en" :selected="language === 'en'">English</option>
                <option value="es" :selected="language === 'es'">Español</option>
              </select>
            </div>
          </div>
        </div>
        <footer id="footer">
          <h2 class="logo_small"><span class="blind" v-html="$t('text_app_logo')"></span></h2>
          <ul class="corp_list">
            <li>
              <a :href="$t('link_service_agreement')" v-html="$t('text_service_agreement')"></a>
            </li>
            <li>
              <a :href="$t('link_privacy_policy')" v-html="$t('text_privacy_policy')"></a>
            </li>
            <li>
              <a :href="$t('link_snow_corp')" v-html="$t('text_snow_corp')"></a>
            </li>
          </ul>
        </footer>
      </div>
  </div>
</template>

<script>
import BrowserChecker from "../util/BrowserChecker";
import LanguageParser from "../util/LanguageParser";

export default {
  name: 'MobileHome',
  data() {
    return {
      isIos: BrowserChecker.isIos(),
      isAndroid: BrowserChecker.isAndroid(),
      language: ''
    }
  },
  methods: {
    changeLanguage: function (event) {
      let language = event.target.value
      if (this.$route.params.language) {
        this.$router.push(this.$route.path.replace(this.$route.params.language, language))
      } else {
        this.$router.push(this.$route.path + "/" + language + "/")
      }
      this.$router.go(0)
    }
  },
  mounted() {
    console.log(this.$route)
    this.language = LanguageParser.parseLanguage(
        this.$route.params.language ? this.$route.params.language : navigator.language)
    this.$i18n.locale = this.language
    document.documentElement.setAttribute('lang', this.language)
  },
  metaInfo() {
    return {
      meta: [
        {
          name: 'viewport',
          content: 'width=device-width,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no'
        }
      ],
    }
  }
}
</script>

<style>
</style>

const ko = {
  text_app_logo: 'GlowUp',
  text_app_title: '완벽한 디테일,<br>프리미엄 인물 편집 앱',
  text_app_desc: '',
  text_app_download: 'Download on the',
  text_app_store: 'App Store',
  text_service_agreement: '이용약관',
  text_privacy_policy: '개인정보처리방침',
  text_snow_corp: '&copy; SNOW Corp.',
  link_service_agreement: 'https://terms.snow.me/GlowUp/TermsOfUse?language=ko',
  link_privacy_policy: 'https://terms.snow.me/GlowUp/PrivacyPolicy?language=ko',
  link_snow_corp: 'https://snowcorp.com/ko/',
  text_selected_language: '한국어'
}

export default ko
import VueI18n from 'vue-i18n'
import Vue from "vue"
import en from "@/i18n/message/en"
import ko from "@/i18n/message/ko"
import es from "@/i18n/message/es"

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: 'en', // set locale
  messages: {
    en: en,
    ko: ko,
    es: es
  }
})

export default i18n

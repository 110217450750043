const en = {
  text_app_logo: 'GlowUp',
  text_app_title: 'Make Every <br>Selfie Glow',
  text_app_desc: 'Advanced photo editor for <br>natural retouches',
  text_app_download: 'Download on the',
  text_app_store: 'App Store',
  text_service_agreement: 'Terms of Use',
  text_privacy_policy: 'Privacy Policy',
  text_snow_corp: '&copy; SNOW Corp.',
  link_service_agreement: 'https://terms.snow.me/GlowUp/TermsOfUse?language=en',
  link_privacy_policy: 'https://terms.snow.me/GlowUp/PrivacyPolicy?language=en',
  link_snow_corp: 'https://snowcorp.com/en/',
  text_selected_language: 'English'
}

export default en
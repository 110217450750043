<template>
  <router-view />
</template>

<script>

export default {
  name: 'App',
  created() {

  }
}
</script>
<style>
</style>

import Vue from 'vue'
import App from './App.vue'
import router from "@/router"
import i18n from "@/i18n"
import vueMeta from "vue-meta"

Vue.config.productionTip = false

Vue.use(vueMeta)

new Vue({
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')

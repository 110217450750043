const es = {
  text_app_logo: 'GlowUp',
  text_app_title: 'Editor Prémium <br>de Retrato',
  text_app_desc: 'Aumenta tu belleza natural',
  text_app_download: 'Download on the',
  text_app_store: 'App Store',
  text_service_agreement: 'Términos de Uso',
  text_privacy_policy: 'Política de privacidad',
  text_snow_corp: '&copy; SNOW Corp.',
  link_service_agreement: 'https://terms.snow.me/GlowUp/TermsOfUse?language=es',
  link_privacy_policy: 'https://terms.snow.me/GlowUp/PrivacyPolicy?language=es',
  link_snow_corp: 'https://snowcorp.com/en/',
  text_selected_language: 'Español'
}

export default es
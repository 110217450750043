<template>
  <mobile-home v-if="isIosOrAndroid" v-show="startShow"></mobile-home>
  <pc-home v-else v-show="startShow"></pc-home>
</template>

<script>
import MobileHome from './MobileHome.vue'
import PcHome from './PcHome.vue'
import BrowserChecker from "@/util/BrowserChecker";

export default {
  components: {MobileHome, PcHome},
  name: 'Content',
  data() {
    return {
      isIosOrAndroid: false,
      startShow: false,
    }
  },
  methods: {
    init: async function () {
      if (BrowserChecker.isIosOrAndroid()) {
        await import('../../public/mo/css/glowup.css')
      } else {
        await import('../../public/pc/css/glowup.css')
      }
      this.isIosOrAndroid = BrowserChecker.isIosOrAndroid()
      this.startShow = true
    }
  },
  mounted() {
    this.init()
  },
}
</script>

<style>
</style>

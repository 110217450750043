<template>
  <div id="wrap" class="glowup_wrap">
    <div id="container">
      <header id="header">
        <!-- PC 해상도 768px을 초과시 노출되는 언어선택영역 -->
        <ul class="language">
          <!-- [D] 선택시 class에 on 추가 -->
          <li :class="language === 'ko' ? 'ko on': 'ko'">
            <a @click="changeLanguage('ko')">한국어</a>
          </li>
          <li :class="language === 'en' ? 'on': ''">
            <a @click="changeLanguage('en')">English</a>
          </li>
          <li :class="language === 'es' ? 'on': ''">
            <a @click="changeLanguage('es')">Español</a>
          </li>
        </ul>
      </header>
      <div class="content">
        <div class="bg"></div>
        <div class="main_box">
          <div class="main">
            <h1 class="title" v-html="$t('text_app_title')"></h1>
            <p v-if="language !== 'ko'" class="text" v-html="$t('text_app_desc')"></p>
            <div class="download">
              <span class="blind" v-html="$t('text_app_download')"></span>
              <a href="https://apps.apple.com/app/id1619090725" class="btn_app_store">
                <span class="blind" v-html="$t('text_app_store')"></span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <footer id="footer">
        <h2 class="logo_small"><span class="blind" v-html="$t('text_app_logo')"></span></h2>
        <ul class="corp_list">
          <li>
            <a :href="$t('link_service_agreement')" v-html="$t('text_service_agreement')"></a>
          </li>
          <li>
            <a :href="$t('link_privacy_policy')" v-html="$t('text_privacy_policy')"></a>
          </li>
          <li class="corp">
            <a :href="$t('link_snow_corp')" v-html="$t('text_snow_corp')"></a>
          </li>
        </ul>
      </footer>
    </div>
  </div>
</template>

<script>
import LanguageParser from "../util/LanguageParser";

export default {
  name: 'PcHome',
  data() {
    return {
      language: '',
    }
  },
  methods: {
    changeLanguage: function (language) {
      if (this.$route.params.language) {
        this.$router.push(this.$route.path.replace(this.$route.params.language, language))
      } else {
        this.$router.push(this.$route.path + "/" + language + "/")
      }
      this.$router.go(0)
    }
  },
  mounted() {
    console.log(this.$route)
    this.language = LanguageParser.parseLanguage(
        this.$route.params.language ? this.$route.params.language : navigator.language)
    this.$i18n.locale = this.language
    document.documentElement.setAttribute('lang', this.language)
  }
}
</script>

<style>
</style>

class LanguageParser {

  static parseLanguage(language) {
    const DEFAULT_LANG = 'en'
    const SUPPORTED_LANGUAGES = ['ko', 'en', 'es']

    if (language == null || undefined) {
      return DEFAULT_LANG
    }

    let result = DEFAULT_LANG
    SUPPORTED_LANGUAGES.forEach(each => {
      if (language.toLowerCase().indexOf(each) >= 0) {
        result = each
      }
    })
    return result
  }
}

export default LanguageParser

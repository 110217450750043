class BrowserChecker {

    static isIos() {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    }

    static isAndroid() {
        return navigator.userAgent.match(/Android/i);
    }

    static isIosOrAndroid() {
        return this.isIos() || this.isAndroid();
    }
}

export default BrowserChecker

import Vue from 'vue'
import VueRouter from 'vue-router'
import Content from '@/view/Content'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/glowup/:language?',
      component: Content
    },
    {
      path: '/:language?',
      component: Content
    }
  ]
})

export default router
